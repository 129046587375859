import React from 'react'
import PropTypes from 'prop-types'

import instagramIcon from '../images/instagram-icon.svg'
import './layout.scss'

const Layout = ({ children }) => (
  <div className="root-container">
    {children}
    <footer>
      ©
      {` ${new Date().getFullYear().toString()} `}
      Louisa Owen Sonstroem
      <a href="https://www.instagram.com/patternmakinginpublicplaces/">
        <img src={instagramIcon} alt="" className="social-icon" />
      </a>
    </footer>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
